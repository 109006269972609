<template>
  <el-container class="home-container">
    <!-- 头部区域 -->
    <el-header>
      <div>
        <span>景德镇市智慧旅游统一票务管理系统</span>
      </div>
      <h6>{{ userName }}, 你好</h6>
      <el-button type="info" @click="logout">退出</el-button>
    </el-header>
    <!-- 卡片视图 -->
    <el-card style="overflow: unset;height: 120px">
      <el-row :gutter="24">
        <el-col :span="8">
          选择时间
          <el-date-picker type="daterange" range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期" v-model="queryInfo.startTime"
                          :picker-options="pickerOption"
                          @change="getDataReportByGoods()"></el-date-picker>
        </el-col>
        <!--        <el-col :span="6">-->
        <!--          选择时间基准-->
        <!--          <el-select v-model="queryInfo.type" placeholder="选择时间基准" @change="getDataReportByGoods()">-->
        <!--            <el-option value="购买时间" label="购买时间">购买时间</el-option>-->
        <!--            <el-option value="验证时间" label="验证时间">验证时间</el-option>-->
        <!--          </el-select>-->
        <!--        </el-col>-->
        <!--        <el-col :span="6">-->
        <!--          选择订单来源-->
        <!--          <el-select v-model="queryInfo.form" placeholder="选择来源" @change="getDataReportByGoods()">-->
        <!--            <el-option value="" label="全部">全部</el-option>-->
        <!--            <el-option value="小程序" label="小程序">小程序</el-option>-->
        <!--            <el-option value="其他" label="其他">其他</el-option>-->
        <!--          </el-select>-->
        <!--        </el-col>-->
        <el-col :span="6">
          <el-button type="primary" @click="reportExcel">导出合作商户日报<i class="el-icon-upload el-icon--right"></i>
          </el-button>
        </el-col>
      </el-row>
    </el-card>

    <el-card style="overflow-y: scroll">
      <el-row :gutter="24">
        <el-col :span="6">
          总票数：{{ sellNum }}张
        </el-col>
        <el-col :span="6">
          总金额：{{ sellMoney }}元
        </el-col>
        <el-col :span="6">
          核验票数：{{ checkNum }}张
        </el-col>
        <el-col :span="6">
          已核验金额：{{ checkMoney }}元
        </el-col>

      </el-row>
      <el-table
          v-for="(item,index) of sellTableData"
          :data="item.data"
          :key="index"
          border
          show-summary
          :summary-method="getSummaries"
          :header-cell-class-name="tableHeaderClassName"
          style="width: 100%">
        <el-table-column :label="`${item.area_name}报表`">
          <el-table-column
              prop="name"
              label="商品名">
          </el-table-column>
          <el-table-column
              prop="price"
              label="单价">
          </el-table-column>
          <el-table-column
              prop="number"
              label="售卖总数">
          </el-table-column>
          <el-table-column
              prop="checked_number"
              label="核验票数">
          </el-table-column>
          <el-table-column
              prop="uncheck_number"
              label="未核验票数">
          </el-table-column>
          <el-table-column
              prop="money"
              label="核验金额">
          </el-table-column>
          <el-table-column
              prop="sell_money"
              label="售出金额">
          </el-table-column>
        </el-table-column>
      </el-table>
      <!--      <el-pagination-->
      <!--        @current-change="handleCurrentChange"-->
      <!--        :current-page="queryInfo.page"-->
      <!--        :page-size="queryInfo.limit"-->
      <!--        layout="total, prev, pager, next, jumper"-->
      <!--        :total="total"-->
      <!--      >-->
      <!--      </el-pagination>-->
    </el-card>
    <el-dialog :visible.sync="dialogVisible" title="导出合作商户日报" width="40%">
      <div class="block">
        <span class="demonstration" style="font-size: 16px;margin-right: 20px">选择日期</span>
        <el-date-picker
            v-model="export_date"
            type="date"
            :picker-options="ex_pickerOption"
            placeholder="选择日期">
        </el-date-picker>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureDownload">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import DateFormat from "@/utils/DateFormat.js";
import * as Export2Excel from "@/utils/Export2Excel.js";

export default {
  name: 'report',
  data() {
    return {
      queryInfo: {
        startTime: [new DateFormat(new Date().setDate(new Date().getDate() - 1)).toString(
            "yyyy-0m-0d"
        ), new DateFormat(new Date().setDate(new Date().getDate() - 1)).toString(
            "yyyy-0m-0d"
        )],
        type: '购买时间',
        page: 1,
        limit: 4,
        form: ''
      },
      sellTableData: [],
      refundTableData: [],
      areaList: [],
      sellNum: 0,
      sellMoney: 0,
      refundNum: 0,
      checkMoney: 0,
      checkNum: 0,
      sellInTypeMoney: [],
      orderTypeOptions: [],
      userName: window.sessionStorage.getItem("userName"),
      total: 0,
      addDialogVisible: false,
      pickerOption: {
        disabledDate: (date) => {
          // 禁用今天及今天之后的日期
          return date.getTime() >= new Date().setDate(new Date().getDate() - 1);
        }
      },
      dialogVisible: false,
      export_date: '',
      ex_pickerOption: {
        disabledDate: (date) => {
          // 禁用今天及今天之后的日期
          const cutoffDate = new Date(2024, 7, 16); // 2024年8月16日，注意月份从0开始
          // 禁用今天及今天之后的日期，和2024年8月16日之前的日期
          return date.getTime() >= new Date().setDate(new Date().getDate() - 1) || date.getTime() < cutoffDate.getTime();
        }
      },
    };
  },
  created() {
    // this.$axios({
    //   url: `/scenery/getScenceList?pageSize=100&currentPage=1`,
    //   method: "get",
    //   timeout: 600000
    // }).then(res=>{
    //   this.areaList=res.data.map(v=>{
    //     return {
    //       value:v.ID,
    //       name:v.景区名称
    //     }
    //   })
    // })
    this.getDataReportByGoods()
  },
  methods: {
    logout() {
      window.localStorage.clear();
      this.$router.push("/").catch(() => {
      });
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getDataReportByGoods();
    },
    getDataReportByGoods() {
      let that = this
      const loading = that.$loading({
        lock: true,
        text: '加载中，请耐心等待',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      console.log(this.queryInfo.startTime)
      if (this.queryInfo.startTime) {
        this.queryInfo.startTime = [new DateFormat(new Date(this.queryInfo.startTime[0])).toString(
            "yyyy-0m-0d"
        ), new DateFormat(new Date(this.queryInfo.startTime[1])).toString(
            "yyyy-0m-0d"
        )]
      }
      that.$axios({
        url: `/manager/monthReport`,
        data: this.queryInfo,
        method: "post",
        timeout: 600000
      })
          .then((res) => {
            console.log(res);
            loading.close();
            if (res.code == 200) {
              that.total = res.total
              that.sellNum = res.data.total_number
              that.checkNum = res.data.total_number_check
              that.sellMoney = res.data.total_money_sell
              that.sellTableData = res.data.list
              that.checkMoney = res.data.total_money
            } else {
              that.sellTableData = []
              that.refundTableData = []
              that.sellNum = 0
              that.sellMoney = 0
              that.refundNum = 0
              that.sellInTypeMoney = []
              that.$message.warning(res.msg)
            }
          })
          .catch((err) => {
            console.log(err)

            loading.close();
          });
    },

    getSummaries(param) {
      const {columns, data} = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总计';
          return;
        }
        if (index === 1) {
          sums[index] = '';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += '';
        } else {
          sums[index] = 'N/A';
        }
      });

      return sums;
    },
    tableHeaderClassName({row, rowIndex}) {
      return 'header-row';
    },
    getOrderTypeOptions() {
      let id = '2e8ac8b3-e201-4c29-91d6-c09deb7785a9'
      let that = this
      that.$axios({
        url: `/mall/enumDetail?id=${id}&timestemp=${new Date().getTime()}`,
        method: "GET",
      })
          .then((res) => {
            if (res.code == 200) {
              this.orderTypeOptions = res.data
            } else {
              that.$message.warning(res.msg)
            }
          })
          .catch((err) => {
            console.log(err)
          });
    },
    reportExcel() {
      this.dialogVisible = true
      return
      let that = this
      const loading = that.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      const params = {
        ticketType: that.queryInfo.ticketType,
        userName: that.userName,
        startDate: new DateFormat(new Date(this.queryInfo.startTime)).toString(
            "yyyy-0m-0d"
        ),
        endDate: new DateFormat(new Date(this.queryInfo.endTime)).toString(
            "yyyy-0m-0d"
        ),
        curTime: new DateFormat(new Date()).toString(
            "yyyy-0m-0d 0h:0f:0s"
        ),
        sellResult: that.sellTableData,
        refundResult: that.refundTableData,
        sellNum: that.sellNum,
        refundNum: that.refundNum,
        sellInTypeMoney: that.sellInTypeMoney,
      }
      const name = `售票员销售日报（${params.ticketType}）_${params.startDate == params.endDate ? params.startDate : params.startDate + "至" + params.endDate}`

      that.$axios({
        url: `/manager/exportExcel`,
        data: {
          data: JSON.stringify(params)
        },
        method: "POST",
        responseType: 'blob'
      }).then(function (data) {
        if (!data) {
          return
        }
        let url = window.URL.createObjectURL(new Blob([data]))
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', name + '.xlsx')

        document.body.appendChild(link)
        link.click()

        loading.close();
        that.$message({
          type: "success",
          message: "正在导出报表...",
        });
      }).catch(function (err) {
        console.log(err)

        loading.close();
      });
    },
    sureDownload() {
      let that = this
      const loading = that.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      console.log(this.export_date)
      this.$axios({
        url: `/manager/dailyReport`,
        data: {date: this.export_date},
        method: "post",
        timeout: 600000
      })
          .then((res) => {
            console.log(res);
            loading.close();
            if (res.code == 200) {
              this.output(res.data)
            } else {
              that.$message.warning(res.msg)
            }
          })
          .catch((err) => {
            console.log(err)
            loading.close();
          });
    },
    output(list) {
      let that=this
      const baseHeader = ['商户名称', '票种名称', '票价(元)', '已支付数量', '已支付订单金额', '已核销数量', '已核销订单金额', '已退款数量', '已退款订单金额']
      const filterVal = ['name', '商品', '票价', '已支付数量', '已支付金额', '已核销数量', '已核销金额', '已退款数量', '已退款金额']
      let total_data=[]
      let merges_index=2
      let merges=[]
      for (const area_name in list) {
        for (const item of list[area_name]) {
          item.name=area_name
          item['已支付金额']=Number((item['已支付金额']||0).toFixed(2))
          item['已核销金额']=Number((item['已核销金额']||0).toFixed(2))
          item['已退款金额']=Number((item['已退款金额']||0).toFixed(2))
        }
        const data = that.formatJson(filterVal, list[area_name])
        total_data.push(...data)
        let next_merges_index=merges_index+data.length
        merges.push(`A${merges_index}:A${next_merges_index-1}`)
        merges_index=next_merges_index
      }
      console.log(merges)
      Export2Excel.export_json_to_excel({
        multiHeader: [baseHeader],
        multiHeader2: [],
        header: [],
        data:total_data,
        filename: `${new DateFormat(new Date(that.export_date)).toString("yyyy-0m-0d")}合作商户日报`,
        merges: merges
      })
    },
    formatJson(filterVal, list) {
      const data = []
      for (let item of list) {
        const _data = []
        for (const val of filterVal) {
          if (val.indexOf('.') !== -1) {
            const split = val.split('.')
            if (split.length === 2) {
              _data.push(item[split[0]][split[1]])
            } else if (split.length === 3) {
              _data.push(item[split[0]][split[1]][split[2]])
            } else {
              _data.push('未知')
            }
          } else {
            _data.push(item[val])
          }
        }
        data.push(_data)
      }
      return data
    }
  }
};
</script>

<style>
.home-container {
  height: 100vh;
}

.el-table .warning-row {
  background: #c1d59c;
}

.el-table .success-row {
  background: #6587b4;
}

.el-table .header-row {
  background: #5d5e5c;
  text-align: center;
}

.el-header {
  background-color: #373d41;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}

.el-aside {
  background-color: #333744;

}

.el-main {
  background-color: #eaedf1;
}

.el-aside .toggle-button {
  background-color: #4A5064;
  color: #fff;
  height: 30px;
  line-height: 30px;
  text-align: center;

}
</style>
